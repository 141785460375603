import React, {useState, useEffect} from "react"
import {useStaticQuery, graphql, Link} from "gatsby"
// import parse from "html-react-parser"
import '../assets/scss/app.scss'
import FacebookSVG from '../assets/svg/facebook-f.svg';
import InstagramSVG from '../assets/svg/instagram.svg';

const Layout = ({ isDark, isHomePage, children }) => {
    const {
        wp: {
            generalSettings: { title },
        },
    } = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        generalSettings {
          title
          description
        }
      }
    }
  `)

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isPastTop, setIsPastTop] = useState(false);

    const menuItems = [
        {
            label: 'Agenda',
            link: '/agenda'
        },
        {
            label: 'Releases',
            link: '/releases'
        },
        {
            label: 'Biografie',
            link: '/biografie'
        },
        {
            label: 'Gallerij',
            link: '/gallerij'
        },
        {
            label: 'Boeken',
            link: '/boeken'
        }
    ]


    useEffect(() => {
        function onScroll() {
            const minScrollPixels = 50;
            if (window.scrollY > minScrollPixels) {
                setIsPastTop(true);
            } else {
                setIsPastTop(false);
            }
        }

        window.addEventListener('scroll', onScroll, false);
        onScroll();

        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    return (
        <div className={`${isDark ? 'bg-black': 'bg-white'} font-sans relative min-h-screen ${isPastTop ? 'jdb--expand' : ''}`} data-is-root-path={isHomePage}>
            <header className="jdb--menu flex items-center z-30 w-full">
                <div className="container mx-auto flex items-center justify-between">
                    <div className={`${isDark || isPastTop ? 'text-white': 'text-black'} font-black text-3xl flex items-center`}>
                        <Link to={"/"} className="ml-3">Jan de Bruijn</Link>
                    </div>
                    <div className="flex items-center">
                        <nav
                            className={`${isDark || isPastTop ? 'text-white': 'text-black'} font-sans uppercase text-lg lg:flex items-center hidden`}>

                            {menuItems.map((item) => {
                                return (
                                    <a key={item.label} href={item.link} className="py-2 px-6 flex hover:text-blue-600">{item.label}</a>
                                )
                            })}
                        </nav>
                        <button className="lg:hidden flex flex-col ml-4" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                            <span className={`${isDark || isPastTop ? 'bg-white': 'bg-black'} w-6 h-1 mb-1`}></span>
                            <span className={`${isDark || isPastTop ? 'bg-white': 'bg-black'} w-6 h-1 mb-1`}></span>
                            <span className={`${isDark || isPastTop ? 'bg-white': 'bg-black'} w-6 h-1 mb-1`}></span>
                        </button>
                    </div>
                </div>


                {(isMenuOpen) && (
                    <div className="origin-top-right absolute top-20 right-0 mt-2 6 shadow-lg w-full bg-black">
                        <div
                            className={`py-1 divide-y divide-gray-100`}
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            {menuItems.map((item) => {
                                return (
                                    <a
                                        key={item.label}
                                        href={item.link || '#'}
                                        className={`${
                                            item.icon ? 'flex items-center' : 'block'
                                        } block px-4 py-2 text-md text-white hover:bg-gray-100 hover:text-gray-900`}
                                        role="menuitem"
                                    >
                                        {item.icon}

                                        <span className="flex flex-col">
                                        <span>{item.label}</span>
                                            {item.desc && <span className="text-gray-400 text-xs">{item.desc}</span>}
                                    </span>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                )}
            </header>

            <main>{children}</main>

            <footer className={"w-full"}>
                <div className={"w-full h-[300px] bg-black flex p-10"}>
                    <div className={"flex-1 text-white font-secondary flex justify-center flex flex-col items-start"}>
                        <span className={"text-xl font-bold"}>Pagina's</span>
                        <ul>
                            {menuItems.map(({label, link}, index) => (
                                <li key={index}><Link to={link} className={"text-white"}>{label}</Link></li>
                            ))}
                        </ul>
                    </div>
                    <div className={"flex-auto text-white justify-center flex flex-col items-center"}>
                        <h1 className={"font-black text-5xl font-secondary"}>Jan de Bruijn</h1>
                        <p>Singer, Guitarist & Songwriter</p>
                        <p>Blues, Jazz, Roots music</p>
                    </div>
                    <div className={"flex-1 text-white flex justify-center flex flex-col items-end"}>
                        <button className={"jdb--button-blue text-4xl"}>Boeken</button>
                    </div>
                </div>

                <div className={"w-full bg-jdb-dark-gray p-10 text-white font-secondary font-black flex"}>
                    <div className={"flex-auto"}>
                        © {new Date().getFullYear()} Jan de Bruijn
                    </div>
                    <div className={"flex-auto flex justify-end items-end"}>
                        <a href={"https://www.facebook.com/jandebruijnmusic"}
                           rel={"nofollow"}
                           target={"_blank"}>
                            <FacebookSVG className={"fill-white max-h-[24px] w-auto pr-5 hover:fill-blue-400"} />
                        </a>

                        <a href={"https://www.instagram.com/jandebruijn3998/"}
                           rel={"nofollow"}
                           target={"_blank"}>
                            <InstagramSVG className={"fill-white max-h-[24px] w-auto hover:fill-blue-400"} />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Layout
